import React from "react"
import styled from "styled-components"
import { lightGray, midGray } from "mill/utils/colors"

const PillBox = styled.div`
  border-radius: 3px;
  background-color: ${lightGray};
  display: inline-block;
  line-height: 3rem;
`

const Pill = styled.a`
  display: inline-block;
  border-radius: 3px;
  background-color: ${props =>
    props.active ? props.theme.colors.primary : lightGray};
  color: ${props => (props.active ? "#ffffff" : midGray)};
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.5;
  text-transform: uppercase;
  line-height: 3rem;
  padding: 0 1rem;
  ${props => !props.active && "cursor: pointer;"}
`

const PillToggle = props => {
  return (
    <PillBox data-toggles>
      <Pill active={props.active == 1} onClick={props.firstHandle}>
        {props.firstToggle}
      </Pill>

      <Pill active={props.active == 2} onClick={props.secondHandle}>
        {props.secondToggle}
      </Pill>
    </PillBox>
  )
}

export default PillToggle
