import styled from "styled-components"
import { sm, md } from "mill/utils/breakpoints"

export const Table = styled.div`
  width: 100%;

  th {
  }

  td {
    font-size: 1.3rem;
    padding: 1.3rem 1.5rem;
  }

  tbody {
    display: block;
    width: 100%;
    overflow: scroll;
    max-height: 50rem;
    tr:nth-child(odd) {
      background: rgb(248, 248, 248);
    }
  }
`

export const TableHeading = styled.div`
  padding-right: 1.4rem;
  display: grid;
  grid-template-columns: 6rem 1fr 8rem;

  strong {
    font-weight: normal;
    padding: 1rem 0.5rem;
    text-align: left;
    font-size: 1.5rem;
  }

  strong[data-desktop="true"] {
    display: none;
  }

  @media ${md} {
    grid-template-columns: 6rem 11rem 4fr 1fr 12rem;

    strong[data-desktop="true"] {
      display: block;
    }
  }
`

export const TableBody = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  max-height: 50rem;
  border-top: 2px solid rgb(235, 235, 235);

  div:nth-child(odd) {
    background: rgb(248, 248, 248);
  }

  span[data-desktop="true"] {
    display: none;
  }

  span {
    font-size: 1.3rem;
    padding: 1.3rem 0.5rem;
  }

  > div {
    display: grid;
    grid-template-columns: 6rem 1fr 8rem;

    @media ${md} {
      grid-template-columns: 6rem 11rem 4fr 1fr 12rem;
      span[data-desktop="true"] {
        display: block;
      }
    }
  }
`

export const LearnerRow = styled.div`
  display: grid;
  grid-template-columns: 6rem 1fr 8rem;
  border-top: 2px solid rgb(235, 235, 235);
  span[data-desktop="true"] {
    display: none;
  }

  @media ${md} {
    grid-template-columns: 6rem 11rem 4fr 1fr 12rem;
    span[data-desktop="true"] {
      display: block;
    }
  }
`
