import React, { useEffect, useState, useRef } from "react"
import { bool, oneOfType, string, number } from "prop-types"
import styled, { keyframes } from "styled-components"

import { sm } from "mill/utils/breakpoints"
import Odometer from "mill/components/Odometer"

const Container = styled.div``

const Label = styled.span`
  display: block;
  font-size: 1.3rem;
  line-height: 1.3em;
  color: rgb(129, 129, 129);
  margin-top: 1rem;
  text-align: ${props => (props.labelAlign ? `${props.labelAlign}` : "left")};
  padding: 0 1.5rem;

  @media ${sm} {
    text-align: center;
  }
`

const Chart = styled.div`
  text-align: center;
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`

const Innards = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Svg = styled.svg`
  display: inline-block;
  position: relative;
  transform: rotate(90deg);
`

const SvgPath = styled.path`
  stroke-width: 7;
  fill: transparent;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: ${props => (props.color ? props.color : props.theme.colors.primary)};
`

const SvgUnderlay = styled(SvgPath)`
  stroke: #e7e7e7;
`

const Percentage = styled.h2`
  margin: 0;
  color: green;
  position: absolute;
  font-size: 2.5rem;
  font-weight: 600;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${props => (props.color ? props.color : props.theme.colors.primary)};
`

const StatisticCircle = ({
  label,
  color,
  percentage,
  labelAlign,
  svgWidth
}) => {
  const [lineLength, setLineLength] = useState(0)
  const [percentageLength, setPercentageLength] = useState(0)
  const underlay = useRef(null)
  const overlay = useRef(null)

  useEffect(() => {
    if (overlay.current) {
      const totalLength = overlay.current.getTotalLength()
      setLineLength(totalLength)
      setPercentageLength(totalLength - (totalLength / 100) * percentage)
    }
  }, [percentage])

  return (
    <Container>
      <Chart>
        <Innards>
          <Percentage color={color}>
            <Odometer to={percentage} />%
          </Percentage>
          <Svg width={svgWidth} viewBox="0 0 100 100">
            <SvgUnderlay
              ref={underlay}
              d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50"
            />
            <SvgPath
              ref={overlay}
              style={{
                strokeDasharray: lineLength,
                strokeDashoffset: percentageLength
              }}
              d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50"
              color={color}
            />
          </Svg>
        </Innards>
      </Chart>
      <Label labelAlign={labelAlign}>{label}</Label>
    </Container>
  )
}

export default StatisticCircle

StatisticCircle.propTypes = {
  svgWidth: oneOfType([string, number]).isRequired,
  color: string.isRequired,
  percentage: number,
  label: string
}

StatisticCircle.defaultProps = {
  loaded: false,
  color: "",
  svgWidth: "80%"
}
