import React from "react"
import styled from "styled-components"
import ProgressBar from "mill/components/ProgressBar"
import { md, lg } from "mill/utils/breakpoints"

const Container = styled.div`
  width: 100%;

  @media ${md} {
    display: inline-block;
  }
`

const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 1rem;
  right: 1rem;
  bottom: 0;
`

const TimeRemaining = styled.h4`
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 1.3rem;
  letter-spacing: 1px;
`

const Title = styled.h4`
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 1.3rem;
  letter-spacing: 1px;
`

const CampaignProgressBar = props => {
  return (
    <Container>
      <Information>
        <Title>Campaign Progress</Title>
        <TimeRemaining>{props.timeRemaining}</TimeRemaining>
      </Information>
      <ProgressBar percentage={props.progressPercentage} />
    </Container>
  )
}

export default CampaignProgressBar
